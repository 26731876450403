var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        staticClass: "q-mb-md",
        attrs: {
          title: "기본정보",
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          customDataTr: "",
          gridHeightAuto: "",
          hideHeader: "",
          hideBottom: "",
          isTitle: true,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customDataTr",
            fn: function (props) {
              return [
                props.rowIndex === 0
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 이름 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.survey.userName) + " "),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 연령 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("c-text", {
                              staticClass: "survey-text",
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                type: "number",
                                label: "",
                                name: "age",
                              },
                              model: {
                                value: _vm.survey.age,
                                callback: function ($$v) {
                                  _vm.$set(_vm.survey, "age", $$v)
                                },
                                expression: "survey.age",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 1
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 성별 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("c-radio", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                codeGroupCd: "SEX_CD",
                                label: "",
                                name: "sexCd",
                              },
                              model: {
                                value: _vm.survey.sexCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.survey, "sexCd", $$v)
                                },
                                expression: "survey.sexCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 결혼여부 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("c-radio", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                codeGroupCd: "MARRIAGE_CD",
                                label: "",
                                name: "marriageFlag",
                              },
                              model: {
                                value: _vm.survey.marriageFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.survey, "marriageFlag", $$v)
                                },
                                expression: "survey.marriageFlag",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 2
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 현직장경력 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-1",
                            attrs: { rowspan: 1, colspan: 3 },
                          },
                          [
                            _c("c-text", {
                              staticClass: "survey-text",
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                label: "",
                                name: "career",
                              },
                              model: {
                                value: _vm.survey.career,
                                callback: function ($$v) {
                                  _vm.$set(_vm.survey, "career", $$v)
                                },
                                expression: "survey.career",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 3
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 작업부서 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-2",
                            attrs: { rowspan: 1, colspan: 3 },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled: true,
                                    editable: _vm.editable,
                                    beforeText: "부",
                                    label: "",
                                    name: "deptName",
                                  },
                                  model: {
                                    value: _vm.survey.deptName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.survey, "deptName", $$v)
                                    },
                                    expression: "survey.deptName",
                                  },
                                }),
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "라인",
                                    label: "",
                                    name: "deptLine",
                                  },
                                  model: {
                                    value: _vm.survey.deptLine,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.survey, "deptLine", $$v)
                                    },
                                    expression: "survey.deptLine",
                                  },
                                }),
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "수행작업",
                                    label: "",
                                    name: "deptWork",
                                  },
                                  model: {
                                    value: _vm.survey.deptWork,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.survey, "deptWork", $$v)
                                    },
                                    expression: "survey.deptWork",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 4
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(" 현재작업"),
                              _c("br"),
                              _vm._v("(구체적으로) "),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-3",
                            attrs: { rowspan: 1, colspan: 3 },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "작업내용",
                                    label: "",
                                    name: "curWorkContents",
                                  },
                                  model: {
                                    value: _vm.survey.curWorkContents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.survey,
                                        "curWorkContents",
                                        $$v
                                      )
                                    },
                                    expression: "survey.curWorkContents",
                                  },
                                }),
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "작업기간",
                                    label: "",
                                    name: "curWorkRange",
                                  },
                                  model: {
                                    value: _vm.survey.curWorkRange,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.survey, "curWorkRange", $$v)
                                    },
                                    expression: "survey.curWorkRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 5
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [_c("span", [_vm._v(" 1일 근무시간 ")])]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-3",
                            attrs: { rowspan: 1, colspan: 3 },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  staticStyle: {
                                    width: "100% !important",
                                    display: "inline-block",
                                    float: "left",
                                  },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    type: "number",
                                    beforeText: "시간",
                                    label: "",
                                    name: "onedayWorkTimeHour",
                                  },
                                  model: {
                                    value: _vm.survey.onedayWorkTimeHour,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.survey,
                                        "onedayWorkTimeHour",
                                        $$v
                                      )
                                    },
                                    expression: "survey.onedayWorkTimeHour",
                                  },
                                }),
                                _c("c-text", {
                                  staticStyle: {
                                    width: "100% !important",
                                    display: "inline-block",
                                    float: "left",
                                  },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    type: "number",
                                    beforeText:
                                      "근무 중 휴식시간(식사시간 제외) [분]",
                                    label: "",
                                    name: "onedayWorkTimeRestMin",
                                  },
                                  model: {
                                    value: _vm.survey.onedayWorkTimeRestMin,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.survey,
                                        "onedayWorkTimeRestMin",
                                        $$v
                                      )
                                    },
                                    expression: "survey.onedayWorkTimeRestMin",
                                  },
                                }),
                                _c("c-text", {
                                  staticStyle: {
                                    width: "100% !important",
                                    display: "inline-block",
                                    float: "left",
                                  },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    type: "number",
                                    beforeText: "휴식 횟수",
                                    label: "",
                                    name: "onedayWorkTimeRestCnt",
                                  },
                                  model: {
                                    value: _vm.survey.onedayWorkTimeRestCnt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.survey,
                                        "onedayWorkTimeRestCnt",
                                        $$v
                                      )
                                    },
                                    expression: "survey.onedayWorkTimeRestCnt",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                props.rowIndex === 6
                  ? _c(
                      "q-tr",
                      { attrs: { props: props, "no-hover": "" } },
                      [
                        _c(
                          "q-td",
                          {
                            staticClass:
                              "text-center th-td-style th-style th-style-1",
                            attrs: { rowspan: 1 },
                          },
                          [
                            _c("span", [
                              _vm._v(" 현작업 전에"),
                              _c("br"),
                              _vm._v("했던 작업 "),
                            ]),
                          ]
                        ),
                        _c(
                          "q-td",
                          {
                            staticClass: "text-center th-td-style td-style-3",
                            attrs: { rowspan: 1, colspan: 3 },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "작업내용",
                                    label: "",
                                    name: "pastWorkContents",
                                  },
                                  model: {
                                    value: _vm.survey.pastWorkContents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.survey,
                                        "pastWorkContents",
                                        $$v
                                      )
                                    },
                                    expression: "survey.pastWorkContents",
                                  },
                                }),
                                _c("c-text", {
                                  attrs: {
                                    disabled: _vm.disabled,
                                    editable: _vm.editable,
                                    beforeText: "작업기간",
                                    label: "",
                                    name: "pastWorkRange",
                                  },
                                  model: {
                                    value: _vm.survey.pastWorkRange,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.survey, "pastWorkRange", $$v)
                                    },
                                    expression: "survey.pastWorkRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _vm.checkboxItems && _vm.checkboxItems.length > 0
            ? _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "체크리스트" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-detail" },
                    _vm._l(_vm.checkboxItems, function (data, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "col-12 mt-1" },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              disabled: _vm.disabled,
                              editable: _vm.editable,
                              isArray: false,
                              comboItems: data.inquiries,
                              isMargin: true,
                              itemText: "inquiryName",
                              itemValue: "heaMuscleWorkerSurveyInquiryId",
                              valueText: "inquiryName",
                              valueKey: "heaMuscleWorkerSurveyInquiryId",
                              label: data.checklistName,
                              name: `value${idx}`,
                            },
                            model: {
                              value: data.value,
                              callback: function ($$v) {
                                _vm.$set(data, "value", $$v)
                              },
                              expression: "data.value",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { isTitle: false, bgClass: "orange" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-radio", {
                  staticClass: "q-ml-sm lastQue",
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    comboItems: [
                      {
                        code: "N",
                        codeName:
                          "아니오(수고하셨습니다. 설문을 다 마치셨습니다.)",
                      },
                      {
                        code: "Y",
                        codeName:
                          "예(“예”라고 답하신 분은 아래 표의 통증부위에 체크(∨)하고, 해당 통증부위의 세로줄로 내려가며 해당사항에 체크(∨)해 주십시오)",
                      },
                    ],
                    valueText: "codeName",
                    valueKey: "code",
                    isMargin: true,
                    label:
                      "귀하의 작업과 관련하여 목, 어깨, 팔/팔꿈치, 손/손가락/손목, 허리, 다리/발 중 어느 한 부위에서라도 통증, 쑤심, 찌릿찌릿함, 뻣뻣함, 화끈거림, 무감각 등을 느끼신 적이 있습니까 ?",
                    name: "lastQue",
                  },
                  model: {
                    value: _vm.survey.lastQue,
                    callback: function ($$v) {
                      _vm.$set(_vm.survey, "lastQue", $$v)
                    },
                    expression: "survey.lastQue",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._m(0),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.neckColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.shoulderColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.armColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.handColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.waistColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("c-table", {
        staticClass: "q-mt-md",
        attrs: {
          title: "",
          editable: _vm.editable && !_vm.disabled,
          columns: _vm.gridLast.legColumns,
          data: _vm.gridLast.data,
          gridHeightAuto: "",
          hideBottom: "",
          isTop: false,
          columnSetting: false,
          isFullScreen: false,
          isExcelDown: false,
          filtering: false,
        },
        on: { headerCheckboxChange: _vm.headerCheckboxChange },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name != "painArea"
                  ? [
                      props.row[`${col.name}Flag`] === "Y"
                        ? _c("c-radio", {
                            attrs: {
                              disabled:
                                _vm.disabled ||
                                (_vm.survey[col.name] === "Y" ? false : true),
                              editable: _vm.editable,
                              comboItems: props.row.comboItems,
                              label: "",
                              name: `value${props.rowIndex}${col.name}`,
                            },
                            model: {
                              value: props.row[col.name],
                              callback: function ($$v) {
                                _vm.$set(props.row, col.name, $$v)
                              },
                              expression: "props.row[col.name]",
                            },
                          })
                        : _vm._e(),
                      props.rowIndex === 5
                        ? [
                            _c(
                              "span",
                              { staticClass: "custom-text-before-parent" },
                              [
                                _c("c-text", {
                                  attrs: {
                                    disabled:
                                      _vm.disabled ||
                                      (_vm.survey[col.name] === "Y"
                                        ? false
                                        : true) ||
                                      !(props.row[col.name] === "etc"),
                                    editable: _vm.editable,
                                    beforeText: "기타 작성",
                                    label: "",
                                    name: `etc${props.rowIndex}${col.name}`,
                                  },
                                  model: {
                                    value: props.row[`${col.name}etc`],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, `${col.name}etc`, $$v)
                                    },
                                    expression: "props.row[`${col.name}etc`]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : [
                      _vm._v(" " + _vm._s(props.row["painArea"]) + " "),
                      props.rowIndex === 2
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" ※"),
                          ])
                        : _vm._e(),
                    ],
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable && !disabled",
                    },
                  ],
                  attrs: {
                    flat: "",
                    isSubmit: _vm.isSave,
                    url: _vm.saveUrl,
                    param: _vm.survey,
                    mappingType: "PUT",
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveSurvey,
                    btnCallback: _vm.saveSurveyCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable && !disabled",
                    },
                  ],
                  attrs: {
                    flat: "",
                    isSubmit: _vm.isComplete,
                    url: _vm.completeUrl,
                    param: _vm.survey,
                    mappingType: "PUT",
                    label: "설문완료",
                    icon: "check",
                  },
                  on: {
                    beforeAction: _vm.completeSurvey,
                    btnCallback: _vm.completeSurveyCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cancelBtnEditable,
                      expression: "cancelBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: "",
                    isSubmit: _vm.isCancel,
                    url: _vm.cancelUrl,
                    param: _vm.survey,
                    mappingType: "PUT",
                    label: "다시작성",
                    icon: "keyboard_return",
                  },
                  on: {
                    beforeAction: _vm.cancelSurvey,
                    btnCallback: _vm.cancelSurveyCallback,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopup },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "line-height": "1.6em",
          "font-size": "0.85em",
          padding: "10px",
          "margin-bottom": "-20px",
        },
      },
      [
        _c("b", [
          _c("span", { staticStyle: { color: "red" } }, [_vm._v("※")]),
          _vm._v(" (3번 질문 참고) 아픈 정도 구분"),
        ]),
        _c("br"),
        _c("ul", [
          _c("li", [
            _vm._v(
              "약한 통증 : 약간 불편한 정도이나 작업에 열중할 때는 못 느낀다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "중간 통증 : 작업 중 통증이 있으나 귀가 후 휴식을 취하면 괜찮다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "심한 통증 : 작업 중 통증이 비교적 심하고 귀가 후에도 통증이 계속된다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "매우 심한 통증 : 통증 때문에 작업은 물론 일상생활을 하기가 어렵다."
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }